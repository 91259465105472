import { createFileRoute } from '@tanstack/react-router';

import { z } from 'zod';

const searchSchema = z.object({
  tab: z
    .union([
      z.literal('imports'),
      z.literal('templates'),
      z.literal('glossary'),
    ])
    .optional()
    .default('imports')
    .catch('imports'),
  search: z.string().optional(),
  ordering: z.string().optional(),
  pageNumber: z.number().optional(),
  itemsPerPage: z.number().optional(),
});

export const Route = createFileRoute(
  '/_secured/company-profile/import-hub/$ingestionType/',
)({
  validateSearch: searchSchema,
});
