import { useEffect } from 'react';

import { useTranslation } from '@mint-lib/i18n';
import {
  useClientProfile,
  useIsControllerLoggedInAsClient,
} from '@mint-lib/profile-helpers';
import { Slot, SlotPortal } from '@mint-lib/slots';
import { Outlet, useNavigate } from '@mint/routing';

import { Box, Link } from '@myn/mui';

import { queryClient } from '../api/queries/main.js';
import Filters from '../features/Filters/index.jsx';

const App = () => {
  const { t } = useTranslation('@myn/permissions');

  const navigate = useNavigate();

  const clientProfile = useClientProfile();

  const isLoggedInAsClient = useIsControllerLoggedInAsClient();
  const handleNavigate = () => {
    navigate({ to: '/company-profile' });
  };

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    body.style.overflow = 'hidden';
    return () => {
      body.style.overflow = 'initial';
    };
  }, []);

  // clear data when user switched ws to prevent previous data
  useEffect(() => {
    return () => {
      queryClient.clear();
    };
  }, []);

  return (
    <>
      {isLoggedInAsClient ? (
        <Box>
          <Link
            variant="bodyShort01"
            icon="ArrowLeft"
            onClick={handleNavigate}
            sx={{
              display: 'inline-flex',
              cursor: 'pointer',
              maxWidth: 'fit-content',
            }}
          >
            {t(`Back to {{Client}} Company Profile`, {
              Client: clientProfile?.profile.company.name,
            })}
          </Link>
        </Box>
      ) : null}
      <SlotPortal name="topBarTitle">{t('Users Management')}</SlotPortal>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: (theme) => theme.spacing(2),
        }}
      >
        <Slot name="filters" />

        <Outlet />
      </Box>
    </>
  );
};

export default App;
