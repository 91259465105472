import * as React from 'react';

import { useTranslation } from '@mint-lib/i18n';
import styled, { isPropValid } from '@mint-lib/styled';
import { PopoverProps } from '@mui/material/Popover';
import SelectM, { SelectProps } from '@mui/material/Select';
import { TooltipProps as TooltipPropsM } from '@mui/material/Tooltip';

import type { IconsList as Icons } from '../../assets/asyncIcons.jsx';
import HelperText from '../../common/Input/HelperText.jsx';
import Label from '../../common/Input/Label.jsx';
import OptionWithCheckbox from '../../common/Select/OptionWithCheckbox.jsx';
import SelectAllCheckbox from '../../common/Select/SelectAllCheckbox.jsx';
import SelectInputWrapper from '../../common/Select/SelectInputWrapper.jsx';
import { GroupType, OptionType, ValueTypes } from '../../types/SelectTypes.js';
import {
  checkForEmptyArray,
  filterOption,
  findLabelInArrayIntersection,
} from '../../utils/selectUtils.js';
import Box from '../Box/Box.jsx';
import FormControl from '../FormControl/FormControl.jsx';
import Icon, { IconProps } from '../Icon/Icon.jsx';
import ListSubheader from '../ListSubheader/ListSubheader.jsx';
import SearchBar from '../SearchBar/SearchBar.jsx';
import Typography from '../Typography/Typography.jsx';

export interface SelectWithSearchProps<VT extends ValueTypes = string> {
  /**
   * Two possibilities of options one for default one {value, label}
   * Second one for group {header, options: {label, value}}
   */
  options: OptionType[] | GroupType[];
  /**
   * If true, value must be an array and the menu will support multiple selections.
   * @default false
   */
  multiple?: boolean;
  /**
   * The input value.
   * Providing an empty string will select no options. Set to an empty string '' if you don't want any of the available options to be selected.
   * If the value is an object it must have reference equality with the option in order to be selected.
   * If the value is not an object, the string representation must match with the string representation of the option in order to be selected.
   * @uxpinbind onChange
   */
  value: VT;
  /**
   * Callback fired when a menu item is selected.
   */
  onChange: (value: VT) => void;
  /**
   * Select Props applies for select component
   */
  selectProps?: Omit<
    SelectProps,
    | 'onChange'
    | 'value'
    | 'multiple'
    | 'onOpen'
    | 'open'
    | 'renderValue'
    | 'displayEmpty'
    | 'defaultValue'
    | 'IconComponent'
  >;
  /**
   * Popover Props applies for Popover component
   */
  popoverProps?: Omit<PopoverProps, 'open' | 'anchorEl' | 'onClose'>;
  /**
   * Label for the select
   */
  label?: string;
  /**
   * Text under select
   */
  helperText?: string;
  /**
   * Placeholder for the select
   */
  placeholder?: string;
  /**
   * If true component state is error
   * @default false
   */
  error?: boolean;
  /**
   * If true component state is disabled
   * @default false
   */
  disabled?: boolean;
  /**
   * The size can be small or medium
   * @default medium
   */
  size?: 'small' | 'medium';
  /**
   * The color of the component
   * @default primary
   */
  color?: 'primary' | 'secondary';
  /**
   * Ability to stretch the entire width of the container
   * @default false
   */
  infoText?: string;
  /**
   * Tooltip props
   */
  TooltipProps?: Partial<TooltipPropsM>;
  HelperTextTooltipProps?: Partial<TooltipPropsM>;
  fullWidth?: boolean;
  largeDropdown?: boolean;
  getOpenState?: (...args: any) => void;
  startIcon?: Icons;
  StartIconProps?: Partial<IconProps>;
  readOnly?: boolean;
  warning?: boolean;
  truncatedOptions?: boolean;
  width?: string;
  /**
   * Limit of options to be selected
   * If limit is set, select all(clear all) button will be hidden
   */
  limit?: number;
  /**
   * A text for tooltip, which will be shown on hover on option
   */
  optionTooltipTitle?: string;
  /**
   * A placement for tooltip
   *@default right
   */
  optionTooltipPlacement?: TooltipPropsM['placement'];
}
const SelectWithSearch = <VT extends ValueTypes>({
  infoText,
  TooltipProps,
  options,
  value,
  onChange,
  multiple,
  selectProps,
  popoverProps,
  label,
  placeholder,
  disabled,
  error,
  helperText,
  size,
  color,
  fullWidth,
  largeDropdown,
  getOpenState,
  startIcon,
  StartIconProps,
  readOnly,
  HelperTextTooltipProps,
  warning,
  truncatedOptions = false,
  width,
  limit,
  optionTooltipTitle,
  optionTooltipPlacement = 'right',
  ...restProps
}: SelectWithSearchProps<VT>) => {
  const { t } = useTranslation('@myn/mui');

  const selectRef = React.useRef<HTMLElement | null>();
  const [search, setSearch] = React.useState<string>('');
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const isGroup = React.useMemo(
    () => options[0] && 'header' in options[0],
    [options],
  );
  const allOptions = React.useMemo(
    () =>
      options
        ?.map((group) => ('options' in group ? group.options : group))
        .flat(),
    [options],
  );
  const handleToggle = () => {
    setIsOpen(!isOpen);
    setSearch('');
    getOpenState && getOpenState(isOpen);
  };
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setSearch(event.target.value);
  };
  const filteredAllOptions = React.useMemo(
    () => allOptions.filter((option) => filterOption(option, search)),
    [search, options],
  );
  const filteredOptions = React.useMemo(() => {
    if (options[0] && 'options' in options[0] && 'header' in options[0]) {
      return (options as GroupType[])
        ?.map((group) => ({
          ...group,
          options: group.options.filter((option: OptionType) =>
            filterOption(option, search),
          ),
        }))
        .filter((group) => group.options.length > 0);
    }
    return filteredAllOptions;
  }, [search, options]);
  const handleSelectAll = () => {
    onChange(filteredAllOptions.map((option) => option.value) as VT);
  };
  const handleClearAll = () => {
    onChange([] as unknown as VT);
  };
  const handleChange = (v: VT) => {
    if (Array.isArray(v)) {
      const val = v.filter((el) => el);
      onChange(val as VT);
    } else if (multiple && typeof v === 'string') {
      // On autofill we get a stringified value.
      onChange(v.split(',') as VT);
    } else {
      if (v) {
        onChange(v as VT);
        setIsOpen(false);
      }
    }
  };

  const showSelectAllClearAll =
    options.length > 0 &&
    multiple &&
    limit === undefined &&
    Array.isArray(value) &&
    filteredOptions.length > 0;
  return (
    <Box>
      {label ? (
        <Label
          disabled={disabled}
          label={label}
          infoText={infoText}
          TooltipProps={TooltipProps}
        />
      ) : null}
      <FormControl
        error={error}
        disabled={disabled}
        color={color}
        focused={isOpen}
        size={size}
        fullWidth={fullWidth}
      >
        <Container $fullWidth={fullWidth}>
          <SelectM
            {...selectProps}
            sx={{
              '.MuiSelect-select': {
                display: 'flex',
                padding: size === 'small' ? '8px 12px' : '12px',
                cursor: readOnly ? 'default' : 'pointer',
              },
            }}
            className={`${readOnly ? 'read-only-input' : ''} ${
              warning ? 'warning-input' : ''
            }`}
            multiple={multiple}
            ref={selectRef}
            readOnly={readOnly}
            onOpen={handleToggle}
            IconComponent={(props) =>
              readOnly ? null : <Icon {...props} component={'ChevronDown'} />
            }
            value={value}
            open={isOpen}
            onChange={(e) => handleChange(e.target.value as VT)}
            displayEmpty
            fullWidth={fullWidth}
            renderValue={(selected) => (
              <SelectInputWrapper
                fullWidth={fullWidth}
                startIcon={startIcon}
                StartIconProps={StartIconProps}
                width={width}
              >
                {checkForEmptyArray(selected) ? (
                  findLabelInArrayIntersection(allOptions, selected)
                ) : (
                  <Placeholder>{placeholder}</Placeholder>
                )}
              </SelectInputWrapper>
            )}
            MenuProps={{
              ...popoverProps,
              variant: 'menu',
              onClose: handleToggle,
              PaperProps: {
                sx: {
                  boxShadow:
                    '0px 2px 4px rgba(9, 30, 66, 0.16), 0px 0px 1px rgba(9, 30, 66, 0.24)',
                  width: truncatedOptions ? width || '270px' : 'inherit',
                  backgroundImage: 'none',
                  marginTop: '4px',
                  borderRadius: '4px',
                },
              },
              MenuListProps: {
                sx: {
                  position: 'relative',
                  maxHeight: largeDropdown ? '20rem' : '13rem',
                  overflow: 'auto',
                },
              },
            }}
            {...restProps}
          >
            {options.length ? (
              <ListSubheader sx={{ padding: '0px 0px 1px 0px' }}>
                <StyledSearchBar
                  color="field02"
                  size="small"
                  value={search}
                  autoFocus
                  onChange={handleSearch}
                  onClear={() => {
                    setSearch('');
                  }}
                  placeholder={t('Search')}
                  $fullWidth={!!fullWidth}
                />
              </ListSubheader>
            ) : null}
            {showSelectAllClearAll ? (
              <div>
                {value.length >= filteredAllOptions.length ? (
                  <SelectAllCheckbox
                    allOptions={filteredAllOptions}
                    value={value}
                    text={t('Clear all')}
                    onClick={handleClearAll}
                    isGroup={isGroup}
                  />
                ) : (
                  <SelectAllCheckbox
                    allOptions={filteredAllOptions}
                    value={value}
                    text={t('Select all')}
                    onClick={handleSelectAll}
                    isGroup={isGroup}
                  />
                )}
              </div>
            ) : null}
            {filteredOptions.length ? (
              filteredOptions?.map((option) => {
                if ('options' in option && 'header' in option) {
                  return [
                    <StyledListSubheader
                      sx={{
                        top: '40px',
                      }}
                    >
                      {option.header}
                    </StyledListSubheader>,
                    ...option.options.map((opt: OptionType) => (
                      <OptionWithCheckbox
                        key={opt.value}
                        value={opt.value}
                        selectValue={value}
                        option={opt}
                        isMultiple={multiple}
                        truncatedOptions={truncatedOptions}
                        limit={limit}
                        tooltipText={optionTooltipTitle}
                        tooltipPlacement={optionTooltipPlacement}
                      />
                    )),
                  ];
                }
                return (
                  <OptionWithCheckbox
                    key={option.value}
                    value={option.value}
                    selectValue={value}
                    option={option}
                    isMultiple={multiple}
                    truncatedOptions={truncatedOptions}
                    limit={limit}
                    tooltipText={optionTooltipTitle}
                    tooltipPlacement={optionTooltipPlacement}
                  />
                );
              })
            ) : (
              <Typography
                variant="bodyShort01"
                padding="11px"
                align="center"
                display="block"
                sx={(theme) => ({ color: theme.palette.text.secondary })}
              >
                {options.length ? 'No results' : 'No options'}
              </Typography>
            )}
          </SelectM>
          {helperText ? (
            <HelperText
              warning={warning}
              HelperTextTooltipProps={HelperTextTooltipProps}
              error={error}
              fullWidth={fullWidth}
              helperText={helperText}
              helperTextWidth="16.875rem"
            />
          ) : null}
        </Container>
      </FormControl>
    </Box>
  );
};
export default SelectWithSearch;

const StyledSearchBar = styled(SearchBar, { shouldForwardProp: isPropValid })<{
  $fullWidth: boolean;
}>(({ $fullWidth, theme }) => ({
  width: $fullWidth ? 'inherit' : 'unset',
  maxWidth: $fullWidth ? '100%' : 'unset',
  '&.MuiPaper-root': {
    borderRadius: '0',
    '&:hover': {
      boxShadow: `0 0 0 1px ${theme.palette.ui.tertiary}`,
    },
    '&:focus-within': {
      boxShadow: `0 0 0 1px ${theme.palette.ui.tertiary}`,
    },
  },
}));

const StyledListSubheader = styled(ListSubheader, {
  shouldForwardProp: isPropValid,
})(({ theme }) => ({
  ...theme.typography.overline01,
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.ui.primary,
  padding: '16px 12px 4px 12px',
  top: '33px !important',
}));

const Placeholder = styled('span')(({ theme }) => ({
  color: theme.palette.text.placeholder,
}));

const Container = styled('div', { shouldForwardProp: isPropValid })<{
  $fullWidth?: boolean;
}>`
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'min-content')};
`;
