import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/')({
  /* mint-disable-next-line no-component-in-sync-route */
  component: Outlet,
  loader: async ({ context }) => {
    await context.serviceLocator.getInstance('i18n', '@myn/mui');
  },
  async beforeLoad({ context, location }) {
    const isAuthenticated = await context.isAuthenticated();
    if (!isAuthenticated && location.pathname === '/') {
      throw redirect({
        to: '/login/',
      });
    } else if (isAuthenticated && location.pathname === '/') {
      throw redirect({
        to: '/campaigns',
      });
    }
    return context;
  },
});
