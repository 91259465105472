import { useContext } from 'react';

import { AuthContext } from '@mint-lib/auth';

import { useProfile } from './useProfile/index.jsx';

export const useIsControllerLoggedInAsClient = () => {
  const auth = useContext(AuthContext);

  const { getProfileField } = useProfile();

  const isController = getProfileField('company')?.controllerCompany;
  return isController && auth.isInterimLoggedIn();
};
