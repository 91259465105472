import { Flagsmith } from '@mint-lib/flags';
import {
  FlagsmithProviderProps,
  FlagsmithProviders,
} from '@mint-lib/flags-envs';

import { type MintServiceLocatorAbstractFactoryContext } from '../types.js';

export async function flagsmithAbstractFactory(
  ctx: MintServiceLocatorAbstractFactoryContext,
  name: keyof typeof FlagsmithProviders,
) {
  const providerSettings: FlagsmithProviderProps = FlagsmithProviders[name];
  const client = new Flagsmith({
    name: name as string,
    environmentID: providerSettings.envID,
  });
  const datadogRum = await ctx.getInstance('datadog');
  if (providerSettings.hasIdentify) {
    const profileService = await ctx.getInstance('profile');

    await client.init({
      cacheFlags: true,
      // @ts-ignore TS2339: Property 'options' does not exist on type.
      ...(providerSettings.options ?? {}),
      datadogRum: {
        client: datadogRum,
        trackTraits: true,
      },
      identity: profileService.getProfileData()?.ffId,
      traits: providerSettings.hasCompanyTraits
        ? {
            cId: profileService.getProfileData()?.profile.company.id ?? null,
          }
        : undefined,
    });
    ctx.addEffect(
      profileService.subscribe(() => {
        client.identify(profileService.getProfileData()?.ffId as string);
      }),
    );
    if (providerSettings.hasCompanyTraits) {
      ctx.addEffect(
        profileService.subscribe(() => {
          client.setTraits({
            cId: profileService.getProfileData()?.profile.company.id ?? null,
          });
        }),
      );
    }
  } else {
    await client.init({
      cacheFlags: true,
      // @ts-ignore TS2339: Property 'options' does not exist on type.
      ...(providerSettings.options ?? {}),
      datadogRum: {
        client: datadogRum,
        trackTraits: true,
      },
    });
  }

  return client;
}
